exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-booking-request-confirmation-tsx": () => import("./../../../src/pages/booking-request-confirmation.tsx" /* webpackChunkName: "component---src-pages-booking-request-confirmation-tsx" */),
  "component---src-pages-booking-request-tsx": () => import("./../../../src/pages/booking-request.tsx" /* webpackChunkName: "component---src-pages-booking-request-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-physicians-tsx": () => import("./../../../src/pages/physicians.tsx" /* webpackChunkName: "component---src-pages-physicians-tsx" */),
  "component---src-pages-practice-with-us-tsx": () => import("./../../../src/pages/practice-with-us.tsx" /* webpackChunkName: "component---src-pages-practice-with-us-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-practices-tsx": () => import("./../../../src/pages/privacy-practices.tsx" /* webpackChunkName: "component---src-pages-privacy-practices-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-what-we-treat-tsx": () => import("./../../../src/pages/what-we-treat.tsx" /* webpackChunkName: "component---src-pages-what-we-treat-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-physician-tsx": () => import("./../../../src/templates/physician.tsx" /* webpackChunkName: "component---src-templates-physician-tsx" */),
  "component---src-templates-program-tsx": () => import("./../../../src/templates/program.tsx" /* webpackChunkName: "component---src-templates-program-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

